import React from 'react'
import ContentLoader from 'react-content-loader'

const CategorieLoader = props => (
  <ContentLoader viewBox="0 0 400 280" height={"40%"} width={"20%"} {...props}>
    <rect x="4" y="242" rx="0" ry="0" width="274" height="40" />
  </ContentLoader>
)

CategorieLoader.metadata = {
  name: 'devent containt loader',
  description: 'devent digital ticket sale',
  filename: 'devent',
}

export default CategorieLoader