import React from "react";
import { Card, CardContent, CardMedia, Typography, Grid, Box } from "@mui/material";
import QRCode from "react-qr-code";
import { Link } from "react-router-dom";

const HorizontalTicket = () => {
  return (
    <div className="row shadow mb-3">
        {/* Image Section */}
        <div className="col-md-3 m-1 d-flex justify-content-center align-items-center" style={{backgroundColor:"#D1711F"}}>
          <img
            src="https://via.placeholder.com/200.png?text=Concert+Image"
            className="img-fluid w-100 rounded-start"
            alt="Concert"
            style={{ objectFit: "cover" }}
          />
        </div>

        {/* Ticket Details */}
        <div className="col-md-6" >
          <div className="card-body">
            <h3 className="card-title">How I Met Your Mother</h3>
            <div className="mb-3">
              Place <h5>Emana Face Tradex</h5>
            </div>
            <div className="mb-3">
              Periode <h5><span className="arrow-calendar">
                  <Link to="#">12/10/2024 - 12h30</Link>
              </span></h5>
            </div>

            {/* QR Code <div className="text-center mt-4">
              <QRCode value="https://example.com/ticket" size={100} />
            </div>*/}
            
          </div>
        </div>
        <div className="col-md-2 m-1 d-flex justify-content-center align-items-center" >
        <button   type="submit" className="button-round " 
        
          style={{backgroundColor:"#D1711F",color:"#fff",width:"100%"}}
          onClick={() => window.open('https://example.com/yourfile.pdf', '_blank')}    
        
        >Download</button>
        </div>
      </div>
  );
};

export default HorizontalTicket;
