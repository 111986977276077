import React from "react";
import { Card, Typography, Box } from "@mui/material";
import QRCode from "react-qr-code";

const MinimalisticTicket = () => {
  return (
    <Card
      sx={{
        position: "relative",
        width: 350,
        height: 200,
        margin: "auto",
        boxShadow: 3,
        overflow: "hidden",
        background: `url(https://via.placeholder.com/350x200.png?text=Concert+Background)`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          bottom: 10,
          left: 10,
          color: "#fff",
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          Jazz Night 2024
        </Typography>
        <Typography variant="body1">Date: 2024-12-20</Typography>
        <Typography variant="body1">Location: Blue Note Club</Typography>
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: 10,
          right: 10,
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          padding: 1,
          borderRadius: 1,
        }}
      >
        <QRCode value="https://example.com/ticket" size={70} />
      </Box>
    </Card>
  );
};

export default MinimalisticTicket;
