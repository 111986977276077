import { Link } from "react-router-dom";
import eventStore from "../domain/usecase/event_service";


const EventPagination = ({ action, size, pageSize }) => {

    const { currentPage, currentPaginationStart,setCurrentPage,setCurrentPaginationStart } = eventStore();
  
    const totalPages = Math.ceil(size / pageSize);
    const maxVisiblePages = 5; // Number of visible page numbers at a time
  
    const handlePageChange = (page) => {
      if (page < 1 || page > totalPages) return; // Prevent invalid page numbers
      setCurrentPage(page);
      //action(page, pageSize); // Trigger the callback
  
      // Update pagination start if moving to a new range of pages
      if (page > currentPaginationStart + maxVisiblePages - 1) {
        setCurrentPaginationStart(currentPaginationStart + maxVisiblePages);
      } else if (page < currentPaginationStart) {
        setCurrentPaginationStart(currentPaginationStart - maxVisiblePages);
      }
    };
  
    const renderPageNumbers = () => {
      const pageNumbers = [];
      for (
        let i = currentPaginationStart;
        i < currentPaginationStart + maxVisiblePages && i <= totalPages;
        i++
      ) {
        pageNumbers.push(
          <li key={i} className={i === currentPage ? 'active' : ''}>
            <Link to="#" onClick={(event) =>{
              event.preventDefault();
              handlePageChange(i)
            }}>
              {i}
            </Link>
          </li>
        );
      }
      return pageNumbers;
    };
  
    return (
      <div className="post-navigation-wrap">
        <nav>
          <ul className="pagination">
            {/* Previous Button */}
            <li>
              <Link
                to="#"
                onClick={(event)=>{
                  event.preventDefault();
                  handlePageChange(currentPage - 1);
                }}
                className={currentPage === 1 ? 'disabled' : ''}
              >
                <i className="fas fa-arrow-left"></i>
              </Link>
            </li>
  
            {/* Page Numbers */}
            {renderPageNumbers()}
  
            {/* Ellipsis for Overflow */}
            {currentPaginationStart + maxVisiblePages <= totalPages && (
              <li>
                <Link to="#">...</Link>
              </li>
            )}
  
            {/* Next Button */}
            <li>
              <Link
                to="#"
                onClick={(event) => {
                  event.preventDefault();
                  handlePageChange(currentPage + 1);
                }}
                className={currentPage === totalPages ? 'disabled' : ''}
              >
                <i className="fas fa-arrow-right"></i>
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    );
  };

export default EventPagination;