import RequestResponse from "../../domain/model/request_responce";
import { request } from "../globalAxios";
import { serviceApi } from "../url";


const createCommander = async (data)=>{
  console.log("createCommander == ",data);
    const response =  await request('post', serviceApi.GET_SAVE_COMMANDER,data);
  return new RequestResponse(response.data,response.sucess,response.pageSize,response.page,response.totalElement,response.totalPages,response.exception);
}
const createCommanderPricing = async ({
  id,
  payMethode,
  numberPlace,
  pricing,
  status,
  commanderId,
  pricingId
})=>{

  var data = {
    "id": id,
    "payMethode": payMethode,
    "numberPlace": numberPlace,
    "status": status,
    "commanderId": commanderId,
    "pricingId": pricingId
  };
  console.log("createCommander == ",data);
    const response =  await request('post', serviceApi.GET_SAVE_COMMANDER_PRICING,data);
  return new RequestResponse(response.data,response.sucess,response.pageSize,response.page,response.totalElement,response.totalPages,response.exception);
}
export{createCommander,createCommanderPricing};