import { Link } from "react-router-dom";
import { ipfsUrl } from "../../data/url";
import { format, parseISO } from "date-fns";
import { Chip, Divider } from "@mui/material";

const MainEventTicketItem = ({event})=>{

//     const { town, country, error, getTownAndCountry } = utilStore();

//    useEffect(() => {
//     getTownAndCountry();
//     }, [getTownAndCountry]);

    const parts = event.mainImage.split("/");
    const imageCID = parts[parts.length - 1];

    return (
       <>
         {/*<Link to={`/event_detail/${event.id}`} state={{event}} className="grid-item col-md-3">
            <article className="product-item text-center">
                <figure className="product-image">
                    
                        <img src={ipfsUrl.IPFS_GET_IMAGE+imageCID} alt="" />
                   
                    {event.quantity===0&&<span className="onsale">Sold out</span>}
                </figure>
                <div className="entry-content">
                <h4>
                    {event!=null&&event.title} 
                </h4>
                <p>
                    {event!=null&&event.summary}
                </p>
                <div className="product-price">
                    {/*<h4> <del>FCFA {event.title}</del></h4>*
                   <h4><ins>From Fcfa 525.00</ins></h4>                  
                </div>
                <div className="entry-meta">
                    <span className="byline">
                        {event.area}
                    </span>
                    <span className="posted-on">
                        {event.country}
                    </span>
                </div>
                <div className="entry-meta">
                    <span className="byline">
                        Start Date
                    </span>
                    <span className="posted-on">
                    {event.startDate}  {event.startHour}
                    </span>
                </div>
                <div className="entry-meta">
                    <span className="byline">
                        End Date
                    </span>
                    <span className="posted-on">
                    {event.dateEnd}  {event.endHour}
                    </span>
                </div>
                </div>
            </article>
        </Link>*/}
            
        <div key={event.id} className="grid-item col-md-4">
            <article className="team-item custom-team-item">
                <figure className="team-img">
                    <img className="image-event-item" src={ipfsUrl.IPFS_GET_IMAGE+imageCID} alt="" />
                    <div className="social-links">
                    <ul>
                        <li>
                            <a href="https://www.facebook.com/" target="_blank"> 
                                <i className="fas fa-share-alt" aria-hidden="true"></i>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.twitter.com/" target="_blank">
                                <i className="fas fa-heart " aria-hidden="true"></i>
                            </a>
                        </li>
                        
                    </ul>
                    </div>
                </figure>
                <div className="entry-content">
                <div className="sidebar">
                    <aside className="widget author_widget">
                        <h3 className="widget-title">{event.quantity===0&&"SOLD OUT"}</h3>
                    </aside>
                </div>
                    
                    <h4>
                        <Link to={`/event_detail/${event.id}`} state={{event}} >{event!=null&&event.title}</Link>
                    </h4>
                    <p>
                        {event!=null&&event.summary}
                    </p>
                    <div className="entry-meta" style={{marginBottom:"0px"}}>
                        <span className="byline">
                            {event.startDate} - {event.startHour}
                        </span>
                        <span className="posted-on">
                            {event.quantity} Places left
                        </span>
                    </div>
                    <div className="entry-meta" >
                        <span className="byline">
                            {event.area}
                        </span>
                        
                    </div>
                    {/*<Divider>TAG</Divider>*/}
                    <div className="row">
                        <div className="post-social-wrap">
                            <div className="social-icon-wrap">
                            {event.tag.split(":").map((label, index) => (
                                <Chip key={index} label={label} style={{marginLeft:"5px",backgroundColor:"#263238",color:"#fff"}}/>
                            ))}
                            </div>
                        </div>
                    </div>

                </div>
            </article>
        </div>
       </>
        
    );
}

export default MainEventTicketItem;
