export const environment  = {
    isRomote: true,
    http: "https",
    localIp: "localhost",
    remoteIp: "devent.my-dservice.com",
    port: 8197,
    ipfsPortGet: 7072,
    ipfsPortSend: 7071
};

export var baseUrl = environment.http+"://" + (environment.isRomote ? environment.remoteIp : environment.localIp) ;
console.log("------ base url --- "+ baseUrl);

///-------------------- remote URL for IPFS ---------------------
export var ipfsUrl = {
    IPFS_GET_IMAGE :environment.http+"://"+(environment.isRomote ? environment.remoteIp : environment.localIp)+":"+environment.ipfsPortGet+"/ipfs/" ,
    IPFS_SEND_IMAGE : baseUrl+":"
}

///-------------------- remote URL for API call -----------------
export var serviceApi = {
    SAVE_CARD:  baseUrl+":"+environment.port+"/public/api/v1/de/card",
    GET_ALL_CARD_API : baseUrl+":"+environment.port+"/public/api/v1/de/card",
    SAVE_EVENT:  baseUrl+":"+environment.port+"/event", 
    SEND_EMAIL : baseUrl+":"+environment.port+"/api/v1/mail",
    GET_ALL_TAG_CATEGORY : baseUrl+":"+environment.port+"/public/tag/category",
    GET_ALL_EVENT : baseUrl+":"+environment.port+"/event/public",
    GET_SAVE_COMMANDER : baseUrl+":"+environment.port+"/commander/public/create",
    GET_SAVE_COMMANDER_PRICING : baseUrl+":"+environment.port+"/commander-pricing",
    GET_ALL_EVENT_CATEGORY : baseUrl+":"+environment.port+"/event/public/by-tevent-category",
    GET_ALL_EVENT_DATE : baseUrl+":"+environment.port+"/event/public/by-date",
    GET_ALL_EVENT_PRICING : baseUrl+":"+environment.port+"/pricing/public/event",
    GET_DETAILS : baseUrl+":"+environment.port+"/event/public/details",
    GET_ALL_CATEGORY : baseUrl+":"+environment.port+"/public/event-category",
    GET_TOWN_COUNTRY : "https://nominatim.openstreetmap.org/reverse?format=json&",
    GET_LATlONG_API: "https://nominatim.openstreetmap.org/search",
    LOGIN_MEMBER : baseUrl+":"+environment.port+"/auth/login",
    SIGNUP_MEMBER : baseUrl+":"+environment.port+"/auth/signup",
    CHECK_TOKEN : baseUrl+":"+environment.port+"/auth/check_token",
    CREATE_ORGANISATION : baseUrl+":"+environment.port+"/public/organisation",
    CREAT_EVENT_PRICING : baseUrl+":"+environment.port+"/pricing",
    CREATE_COMMANDER: baseUrl+":"+environment.port+"/commander/create",
}

