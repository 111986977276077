class RequestResponse {
    constructor(data, success,pageSize,page,totalElements,totalPages,exception) {
      this.data = data;
      this.success = success;
      this.pageSize = pageSize;
      this.page = page
      this.exception = exception;
      this.totalElements = totalElements;
      this.totalPages = totalPages;
    }
  }
  
  export default RequestResponse;