import React from "react";
import { Card, CardContent, CardMedia, Typography, Box } from "@mui/material";
import QRCode from "react-qr-code";

const VerticalTicket = () => {
  return (
    <Card sx={{ maxWidth: 400, margin: "auto", boxShadow: 3 }}>
      <CardMedia
        component="img"
        height="200"
        image="https://via.placeholder.com/400x200.png?text=Concert+Image"
        alt="Concert"
      />
      <CardContent>
        <Typography variant="h5" component="div" gutterBottom>
          Live Concert 2024
        </Typography>
        <Typography variant="body1" color="text.secondary">
          Date: 2024-12-25
        </Typography>
        <Typography variant="body1" color="text.secondary">
          Location: Madison Square Garden
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <QRCode value="https://example.com/ticket" size={100} />
        </Box>
      </CardContent>
    </Card>
  );
};

export default VerticalTicket;
