import { Link,useNavigate } from "react-router-dom";
import eventStore from "../../domain/usecase/event_service";
import MainEventTicketItem from "./main_event_ticket_item";
import { useEffect } from "react";
import TagFilter from "../tag_filter";
import ContainLoader from "../shommer loader/containt_loader";
import CategorieLoader from "../shommer loader/categorie_loadeer";


const EventListLayout = ()=>{
   const { events, loading, error, fetchEvents } = eventStore();

   const navigate = useNavigate();
  
   const handleClick = () => {
      navigate(`/filter_event/${"all_event"}`);
   };

   useEffect(() => {
      fetchEvents(1);
    }, [fetchEvents]);

   if (loading) return(
        <div className="product-outer-wrap product-wrap">
            <div className="container">
            <div className="product-notices-wrapper">
            {[1, 2, 3,4].map((_, index) => <CategorieLoader key={index}/> )}
            </div>
            <div className="product-item-wrapper">
            {[1, 2, 3].map((_, index) => <ContainLoader key={index}/> )}
            </div>
            </div>
            
            
        </div>
    );
   if (events!=null&&error) return <p>Error: {error}</p>;

    return(
        <>
            {/*product section html start*/}
            <div className="product-outer-wrap product-wrap">
                <div className="container">
                    <div >
                        {/*bloc filter event*/}
                        <div className="product-notices-wrapper">
                           <form method="get col-md-4">
                              <select name="orderby" className="orderby" aria-label="Shop order">
                                 <option value="menu_order"  >My Location</option>
                                 <option value="rating">My Town</option>
                                 <option value="rating">My Country</option>
                                 <option value="date">Auther Country</option>                                 
                              </select>
                              <div className="post-navigation-wrap">                            
                              </div>
                           </form>
                           <TagFilter />
                        </div>
                        <div className="product-item-wrapper">
                            <div className="row">
                              {events!=null&&events.slice(0, 6).map((event)=> <MainEventTicketItem key={event.id} event={event}/>)}
                            </div>
                            {events!=null&&events.length>6&&<div className="row">
                              <button onClick={handleClick}  type="submit" className="button-round col-md-4 mx-auto">See more</button>
                            </div>}
                        </div>
                        
                        {/*pagination html start*/}
                       
                    </div>
                </div>
            </div>
        </>
    );
}

export default EventListLayout;