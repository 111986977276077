import { t } from "i18next";
import { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import EventDetailService from "../domain/usecase/cards_detail_services";
import { useNavigate } from 'react-router-dom';
import CreateEventService from "../domain/usecase/create_event_service";
import PricingStore from "../domain/usecase/model_pricing_service";
import toast from "react-hot-toast";
import MemberManagementService from "../domain/usecase/member_management_service";
import ModalSubscription from "../components/modal_subscription_login/modal_subscription";
import Loader from "../components/loader";

const PricingComponant = ({place,amount,desc,keyPlace})=>{
    return(
        <>
            <li>
                <div className="row">
                    <h5>
                        {keyPlace} - {amount} XAF 
                    </h5>
                    <span className="byline">
                        {place>0?place+" Places remind":"SOLD OUT"}
                    </span>
                    {place>0&&<span className="byline">
                            {desc}
                    </span>}
                    
                </div>                
            </li>
        </>
    );
}

const EventPreview = ()=>{
    const { saveEvent,eventData,loading,switchStep,initData,storeMainImage,storeDescriptionImage1,storeDescriptionImage2} = CreateEventService();
    const { toggleShowMap, showMap } = EventDetailService();
    const { pricings} = PricingStore();
    const {showLogin,toogleShowLogin,isLogin,isSubscription,isconnected,isTokenValide} = MemberManagementService();

    const navigate = useNavigate();    
    const goBack = () => {
        navigate(-1);  // This will navigate back to the previous page
    };

    useEffect(()=>{
        initData(); 
    },[initData]);

    return(
        <>
            <Fragment>
                <main id="content" className="site-main">
                        {/*<!-- Inner Banner html start-->{t('COMMON_SEARCH',{ns:"common"})}*/}
                    <section className="inner-banner-wrap">
                        <div className="inner-baner-container">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-md-6 text-left">
                                        <div className="banner-content">
                                            <h3 className="inner-title">{eventData.title}</h3>
                                            <span className="inner-title">{eventData.summary}</span>
                                            <div className="entry-meta entry-meta-event-detail">
                                                <span className="arrow-calendar">
                                                    <Link to="#">{eventData.startDate} - {eventData.startHour}</Link>
                                                </span>
                                                <span className="arrow-calendar">
                                                    <Link to="#">{eventData.dateEnd} - {eventData.endHour}</Link>
                                                </span>
                                                <span className="arrow-pers">
                                                    <Link to="#">{eventData.quantity} places</Link>
                                                </span>
                                            </div>
                                            <div className="entry-meta entry-meta-event-detail">
                                                <span className="posted-on">
                                                    <Link to="/">Home</Link>
                                                </span>
                                                <span className="arrow-link">
                                                    <Link to="/event_tickets">Event Ticket</Link>
                                                </span>
                                                <span className="arrow-link">
                                                    <Link to="#">{eventData.title}</Link>
                                                </span>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col col-md-12">
                                                <button   type="submit" className="button-round col-md-4 mx-auto" style={{backgroundColor:"white",color:"grey"}}>Cancel</button>
                                                </div>                            
                                            </div>
                                            
                                        
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row">
                                            <figure className="banner-image"> 
                                                <img
                                                src={storeMainImage}
                                                alt="Preview"
                                                style={{ maxWidth: '100%', maxHeight: '300px' }}
                                                />
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="overlay-image"></div>
                            <div className="bottom-shape">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                                    <path className="elementor-shape-fill" d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
                                    c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
                                    c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"></path>
                                </svg>
                            </div>
                        </div>
                    </section>
                    <div className="checkout-section">
                        <div className="container">
                            <div className="post-navigation">
                                 <div className="nav-prev">
                                    <label className="event-label">ABOUT AUTHOR</label>
                                 </div>
                                 <div className="nav-next">
                                    <i className="fas fa-share-alt"></i>
                                    &nbsp;&nbsp;&nbsp;
                                    <i className="fas fa-heart "></i>
                                 </div>
                              </div>
                            <div className="row mt-5">
                                {/*<!-- Event details start -->*/}
                                <div className="col-md-8 right-sidebar">
                                    
                                    {/*<!-- Event details localization -->*/}
                                    <span className="byline">
                                        <h4>Location</h4>
                                        <div className="row mt-2 ">
                                            <i className="fa fa-map-marker">&nbsp;&nbsp;&nbsp;&nbsp;{eventData.country}</i>
                                        </div>
                                        <div className="row mt-2">
                                            <i className="fa-solid fa-chevr">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{eventData.area}</i>
                                        </div>
                                    </span>
                                    
                                    <div id="accordion-tab-one" className="accordion-content mt-2" role="tablist">
                                        <div id="accordion-B" className="card tab-pane" role="tabpanel" aria-labelledby="accordion-B">
                                            <div className="card-header" role="tab" id="qus-B" onClick={toggleShowMap}>
                                                <h6 className="mb-0">
                                                    <a className="collapsed" data-bs-toggle="collapse" href="#collapse-two" aria-expanded="true" aria-controls="collapse-two">
                                                        {showMap?"Afficher la carte":"Fermer la carte"}  
                                                    </a>
                                                </h6>
                                            </div>
                                            <div id="collapse-two" className="collapse" data-bs-parent="#accordion-tab-one" role="tabpanel" aria-labelledby="qus-B">
                                                <div className="card-body">
                                                    <div className="map-section mt-3">
                                                        <iframe src={"https://maps.google.com/maps?q="+eventData.area+"&t=&z=12&ie=UTF8&iwloc=&output=embed"} height="300" width={"100%"} allowFullScreen="" loading="lazy"></iframe>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/*<!-- Event details policies -->*/}
                                    <div className="row mt-5">
                                        <span className="byline">
                                            <h4>Politique de remboursements</h4>
                                            <div className="row ">
                                            &nbsp; &nbsp;Pas de remboursements
                                            </div>
                                        </span>
                                    </div>
                                    {/*<!-- Event details policies -->*/}
                                    <div className="row mt-5">
                                        <span className="byline">
                                            <h4>À propos de cet évènement</h4>
                                            <div className="row mt-2 ">
                                                <span className="byline">
                                                    <div dangerouslySetInnerHTML={{ __html: eventData.description }} />
                                                </span>
                                            </div>
                                        </span>
                                    </div>
                                    
                                    <div className="row mt-3">
                                        <div className="col-md-6">
                                            <figure>
                                                <img
                                                src={storeDescriptionImage1}
                                                alt="Preview"
                                                style={{ maxWidth: '100%', maxHeight: '300px' }}
                                                />
                                                </figure>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-md-6">
                                            <figure>
                                                <img
                                                src={storeDescriptionImage2}
                                                alt="Preview"
                                                style={{ maxWidth: '100%', maxHeight: '300px' }}
                                                />
                                            </figure>
                                        </div>
                                    </div>

                                    
                                </div>
                                {/*<!-- Pricing  -->*/}
                                <div className="col-md-4">
                                    <aside className="sidebar">
                                        <div className="widget-bg widget-paymet-bank">
                                            <div className="payment-wrap">
                                                <h4 className="bg-title">Pricing</h4>
                                                <div className="form-group">
                                                    
                                                    <aside className="widget widget_product_post widget-product-thumb">
                                                        <ul>      
                                                            {pricings!==null&&pricings.map((p,keyId)=><PricingComponant place={p.numberPlace} key={keyId} amount={p.amount} desc={p.options} keyPlace={p.category} />)}             
                                                        
                                                        </ul>
                                                    </aside>
                                                </div>                                                
                                            </div>
                                            
                                        </div>
                                    </aside>
                                </div>
                            </div>
                            {/*<!-- similar tag -->*/}
                            <div className="row mt-5">
                                <span className="byline">
                                    <h4>Tags</h4>
                                    <div className="row mt-2 ">
                                        
                                        <div className="row">                        
                                            <div className="post-socail-wrap">
                                                <div className="social-icon-wrap ">
                                                    {eventData.tag.split(":").map((e)=><Link to="#" className="outline-round my-outline-round">{e}</Link>)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </span>
                            </div>
                            
                        </div>
                    </div>
                    {/*<!-- Event details end -->*/}
                    <div className="myfooter">
                        <div className="row">
                        <button onClick={()=>{
                            switchStep(5);
                            goBack();
                        }} type="submit" className="button-round col-md-4 mx-auto m-1" style={{backgroundColor:"white",color:"grey"}}>Back for edit</button>
                        {loading?<button type="submit" className="button-round col-md-4 mx-auto m-1">LOADING ...</button>:<button onClick={()=>{
                            
                            if (isconnected&&isTokenValide) {
                                toast.success("connected");
                                saveEvent(pricings,navigate);
                              }else{
                                toogleShowLogin(true);
                              }
                        }} type="submit" className="button-round col-md-4 mx-auto m-1">SAVE YOUR EVENT</button>}
                        </div>
                    </div>
                </main>
            </Fragment>
            {showLogin&&<ModalSubscription show={showLogin} toogleShow={()=>toogleShowLogin(false)} isLogin={isLogin} isSubscription={isSubscription}/>}
        </>
    );
}

export default EventPreview;