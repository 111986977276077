
import {create} from 'zustand';
import { getTownAndCountry } from '../data/api/util_api';
import dayjs from 'dayjs';

// const latitude = '3.8743742';
// const longitude = '11.4931874';

const utilStore = create((set,get) => ({
  town: "",
  country: "",
  loading: false,
  error: null,
  getTownAndCountry: async (latitude, longitude) => {
    set({ loading: true, error: null });
    try {
      const response = await getTownAndCountry(latitude, longitude);
      const data = await response.json();
      
      //const town = data.address.city || data.address.town || data.address.village || '';
      const town = data.address.town || '';
      const country = data.address.country || '';
      set({town,country, loading: false });    
    } catch (error) {
      console.error('Error:', error);
    }
  },
}));


const convertDateFormat = (dateString) => {
    // Parse the input date string into a Date object
  const dateParts = dateString.split('-');
  const year = dateParts[0];
  const month = dateParts[1] - 1; // Months are 0-based in JS Date
  const day = dateParts[2];
  const date = new Date(year, month, day);

  // Format the date to 'Month day, year'
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);

  return formattedDate;
  };

  const convertDate = (datetime)=>{
    var date = datetime!==""?new Date(datetime):new Date();
    var day = String(date.getDate()).padStart(2, '0');
    var month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    var year = date.getFullYear();
    var formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
  }

  const convertDateYearMonthDay = (datetime)=>{
    var date = datetime!==""?new Date(datetime):new Date();
    var day = String(date.getDate()).padStart(2, '0');
    var month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    var year = date.getFullYear();
    var formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }

  const convertTime = (timestamp)=>{
    const date = new Date(timestamp);

    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');

    const timeString = `${hours}:${minutes}:${seconds}`;
    return timeString;
  }

  const getDateInterval = (keyword) => {
    const now = dayjs();
  
    switch (keyword.toLowerCase()) {
      case "today":
        return {
          startDate: convertDateYearMonthDay(now.startOf("day").toDate()),
          endDate: convertDateYearMonthDay(now.endOf("day").toDate()),
        };
  
      case "this_week":
        return {
          startDate: convertDateYearMonthDay(now.startOf("week").toDate()),
          endDate: convertDateYearMonthDay(now.endOf("week").toDate()),
        };
        case "this_weekend":
          return {
            startDate: convertDateYearMonthDay(now.startOf("week").toDate()),
            endDate: convertDateYearMonthDay(now.endOf("week").toDate()),
          };  
  
      case "this_month":
        return {
          startDate: convertDateYearMonthDay(now.startOf("month").toDate()),
          endDate: convertDateYearMonthDay(now.endOf("month").toDate()),
        };
  
      default:
        throw new Error("Invalid keyword. Valid keywords are 'today', 'this week', or 'month'.");
    }
  }

  const base64ToFile = (base64Data, fileName) => {
    
    // Extract the base64 data
    const byteString = atob(base64Data.split(',')[1]);
    
    // Create an ArrayBuffer to hold the binary data
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uintArray = new Uint8Array(arrayBuffer);
    
    // Write the binary data into the Uint8Array
    for (let i = 0; i < byteString.length; i++) {
      uintArray[i] = byteString.charCodeAt(i);
    }
  
    // Create a Blob with the correct MIME type
    const blob = new Blob([uintArray], { type: 'image/jpeg' });
  
    // Create and return a File object
    return new File([blob], fileName, { type: 'image/jpeg' });
  }


export {utilStore,convertDateFormat,convertDate,convertTime,convertDateYearMonthDay,getDateInterval,base64ToFile};


