import { t } from "i18next";
import { Fragment, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { ipfsUrl } from "../data/url";
import { loadFn } from "../script/custom";
import EventDetailService from "../domain/usecase/cards_detail_services";
import TagFilter from "../components/tag_filter";
import ModalPayement from "../components/modal_payement/modal_payement";
import PricingComponant from "../components/pricing_componant";
import PricingDetails from "../components/pricing_details";

const EventDetails = ()=>{
    const { toggleShowMap, showMap,event ,fetcheventdetails,imageCID,imageCID1,imageCID2,listPricing,totalNumberPlace} = EventDetailService();
    const { id } = useParams();
    const location = useLocation();
    //const { event } = location.state || {};

    

    useEffect(() => {
        loadFn();
        fetcheventdetails(id);
        
    }, [fetcheventdetails])


    return(
        <>
            <Fragment>
                <main id="content" className="site-main">
                        {/*<!-- Inner Banner html start-->{t('COMMON_SEARCH',{ns:"common"})}*/}
                    <section className="inner-banner-wrap">
                        <div className="inner-baner-container">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-md-6 text-left">
                                        <div className="banner-content">
                                            <h3 className="inner-title">{event.title}</h3>
                                            <span className="inner-title">{event.summary}</span>
                                            <div className="entry-meta entry-meta-event-detail">
                                                <span className="arrow-calendar">
                                                    <Link to="#">{event.startDate}-{event.startHour}</Link>
                                                </span>
                                                <span className="arrow-calendar">
                                                    <Link to="#">{event.dateEnd}-{event.endHour}</Link>
                                                </span>
                                                <span className="arrow-pers">
                                                    <Link to="#">{totalNumberPlace} places</Link>
                                                </span>
                                            </div>
                                            <div className="entry-meta entry-meta-event-detail">
                                                {listPricing.map((p)=><span className="ticket-on">
                                                    <Link to="#">{p.priceCategorie}- {p.cost} Fcfa</Link>
                                                </span>)}
                                                
                                            </div>
                                            <div className="entry-meta entry-meta-event-detail">
                                                <span className="arrow-link">
                                                    <Link to="/">Home</Link>
                                                </span>
                                                <span className="arrow-link">
                                                    <Link to="/event_tickets">Event Ticket</Link>
                                                </span>
                                                <span className="arrow-link">
                                                    <Link to="#">{event.title}</Link>
                                                </span>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col col-md-12">
                                                    <ModalPayement />
                                                </div>                            
                                            </div>
                                            
                                        
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row">
                                            <figure className="banner-image"> 
                                                <img src={ipfsUrl.IPFS_GET_IMAGE+imageCID}  alt=""/>
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="overlay-image"></div>
                            <div className="bottom-shape">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                                    <path className="elementor-shape-fill" d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
                                    c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
                                    c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"></path>
                                </svg>
                            </div>
                        </div>
                    </section>
                    <div className="checkout-section">
                        <div className="container">
                            <div className="post-navigation">
                                 <div className="nav-prev">
                                    <label className="event-label">ABOUT AUTHOR</label>
                                 </div>
                                 <div className="nav-next">
                                    <i className="fas fa-share-alt"></i>
                                    &nbsp;&nbsp;&nbsp;
                                    <i className="fas fa-heart "></i>
                                 </div>
                              </div>
                            <div className="row mt-5">
                                {/*<!-- Event details start -->*/}
                                <div className="col-md-8 right-sidebar">
                                    {/*<!-- Event details localization -->*/}
                                    <span className="byline">
                                        <h4>Location</h4>
                                        <div className="row mt-2 ">
                                            <i className="fa fa-map-marker">&nbsp;&nbsp;&nbsp;&nbsp; {event.country}</i>
                                        </div>
                                        <div className="row mt-2">
                                            <i className="fa-solid fa-chevr">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{event.area}</i>
                                        </div>
                                    </span>
                                    
                                    <div id="accordion-tab-one" className="accordion-content mt-2" role="tablist">
                                        <div id="accordion-B" className="card tab-pane" role="tabpanel" aria-labelledby="accordion-B">
                                            <div className="card-header" role="tab" id="qus-B" onClick={toggleShowMap}>
                                                <h6 className="mb-0">
                                                    <a className="collapsed" data-bs-toggle="collapse" href="#collapse-two" aria-expanded="true" aria-controls="collapse-two">
                                                        {showMap?"Afficher la carte":"Fermer la carte"}  
                                                    </a>
                                                </h6>
                                            </div>
                                            <div id="collapse-two" className="collapse" data-bs-parent="#accordion-tab-one" role="tabpanel" aria-labelledby="qus-B">
                                                <div className="card-body">
                                                    <div className="map-section mt-3">
                                                    <iframe src={"https://maps.google.com/maps?q="+event.area+"&t=&z=12&ie=UTF8&iwloc=&output=embed"} height="500" width={"100%"} allowFullScreen="" loading="lazy"></iframe>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/*<!-- Event details policies -->*/}
                                    {/*<div className="row mt-5">
                                        <span className="byline">
                                            <h4>Politique de remboursements</h4>
                                            <div className="row ">
                                            &nbsp; &nbsp;Pas de remboursements
                                            </div>
                                        </span>
                                    </div>*/}
                                    {/*<!-- Event details policies -->*/}
                                    <div className="row mt-5">
                                        <span className="byline">
                                            <h4>À propos de cet évènement</h4>
                                            <div className="row mt-2 ">
                                                <div dangerouslySetInnerHTML={{ __html: event.description }} />                                                
                                            </div>
                                        </span>
                                    </div>
                                    
                                    <div className="row mt-3">
                                        <div className="col-md-6"><figure><img src={ipfsUrl.IPFS_GET_IMAGE+imageCID1}  alt=""/></figure></div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-md-6"><figure><img src={ipfsUrl.IPFS_GET_IMAGE+imageCID2}  alt=""/></figure></div>
                                    </div>

                                    
                                </div>
                                {/*<!-- Pricing  -->*/}
                                <div className="col-md-4">
                                    <aside className="sidebar">
                                        <div className="widget-bg widget-paymet-bank">
                                            <div className="payment-wrap">
                                                <h4 className="bg-title">Pricing</h4>
                                                <div className="form-group">
                                                    
                                                    <aside className="widget widget_product_post widget-product-thumb">
                                                        <ul>      
                                                            {listPricing.map((p)=><PricingDetails place={p.nbrTicket} amount={p.cost} desc={p.collation} keyPlace={p.priceCategorie} />)}              
                                                            
                                                        </ul>
                                                    </aside>
                                                </div>                                                
                                            </div>
                                           
                                            <ModalPayement />
                                        </div>
                                    </aside>
                                </div>
                            </div>
                            {/*<!-- similar tag -->*/}
                            <div className="row mt-5">
                                <span className="byline">
                                    <h4>Tags</h4>
                                    <div className="row mt-2 ">
                                        <TagFilter />
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                    {/*<!-- Event details end -->*/}
                </main>
            </Fragment>
        </>
    );
}

export default EventDetails;