
const FormPayementBankCard = ()=>{

    return (
        <>
            <div className='row mt-2'>
                <div className="col-12">
                    <div className="form-group">
                    <label>Full name *</label>
                    <input type="text" name="fullname"  />
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-group">
                    <label>Valide card number *</label>
                    <input type="text" name="cardnumber"  />
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="form-group">
                    <label>Expiration Date *</label>
                    <input type="text" name="expiredate" placeholder="MM / YY *" />
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="form-group">
                    <label>CVV *</label>
                    <input type="text" name="cvv" placeholder="123" />
                    </div>
                </div>
                {/*<div className="col-sm-4">
                    <div className="form-group">
                    <label>Zip code *</label>
                    <input type="text" name="name" placeholder="exemple: 95" />
                    </div>
                </div>*/}
            </div>
        </>
    );
}

export default FormPayementBankCard;

