import React, { Fragment, useEffect } from "react";
//import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";
//import InteractionVM from "../view_model/interaction_vm";
import { useTranslation } from "react-i18next";

import { loadFn } from '../script/custom'
import EventCategoryItem from "../components/event_category_item";
import EventListLayout from "../components/main_event_ticket/event_list_layout";
import TopEvent from "../components/main_event_ticket/top_event";
import DownloadApp from "../components/download_app";
import CreateEventService from "../domain/usecase/create_event_service";

const MainTicketEvent = ()=>{ 
    //const navBarState = InteractionVM((state)=>state.navBar);
    //const navBarAction = InteractionVM((state)=>state.navigateAction);
    const {t} = useTranslation(['welcome_event','common']);

    const { switchStep,resetStore} = CreateEventService();

    useEffect(() => {
        
        loadFn()
    }, [])

    return (
        <Fragment>
            {/* <Helmet>
                <script src="/assets/js/custom.js" async></script>
            </Helmet> */}
            <main id="content" className="site-main">
                {/*main ticket event banner*/}
                <section className="home-banner">
                    <div className="container">
                        <div className="home-banner-inner">
                
                            <div className="row align-items-center">
                                
                                <div className="col-lg-6">    
                                <aside className="widget search_widget">
                                        <form>
                                            <input type="text" name="search" placeholder="Search.." />
                                            <button className="search-btn">
                                                <i className="fas fa-search"></i>
                                            </button>
                                        </form>
                                    </aside>                
                                    <div className="banner-content">
                                        <div className="sub-title">{t('HEADER_TITLE1')}</div>
                                        <h2 className="banner-title">{t('HEADER_TITLE2')}</h2>
                                        <div className="banner-text">
                                            <p>{t('HEADER_P1')} <b>{t('COMMON_CONCERT',{ns:"common"})}</b> - <b>{t('COMMON_FORUM',{ns:"common"})}</b> - <b>{t('COMMON_TRANSPORT',{ns:"common"})}</b> {t('HEADER_P2')} <b>{t('COMMON_EVENT',{ns:"common"})}</b> {t('HEADER_P3')}</p>
                                        </div>
                                        <div className="banner-button">
                                            <Link to={`/create_event/step1/${"CREATE EVENT"}`} onClick={()=>{
                                                
                                                switchStep(1);
                                                resetStore();
                                            }} className="button-round" >{t('CREATE_AN_EVENT',{ns:"common"})}</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <figure className="banner-image">
                                        <img src="assets/images/header2.png" alt=""/>
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="overlay"></div>
                </section>
                {/*home banner section html end*/}
                {/*home process section html start*/}
                <div className="process-section">
                    <div className="container">
                        <div className="row">
                            <EventCategoryItem url={`/event_detail/$dd`} title={`${t('COMMON_DISCOVERY',{ns:"common"})}`} icon="icon-diamond1"/>
                            <EventCategoryItem url="" title={`${t('COMMON_INTERTENMENT',{ns:"common"})}`} icon="icon-diamond1"/>
                            <EventCategoryItem url="" title={`${t('COMMON_TRAVEL',{ns:"common"})}`} icon="icon-diamond1"/>
                            <EventCategoryItem url="" title={`${t('COMMON_fORUM',{ns:"common"})}`} icon="icon-diamond1"/>                      
                        </div>
                    </div>
                </div>
                {/*home process section html end*/}                
                <EventListLayout />
                {/*Top Event slider*/}
                <section className="app-section">
                <div className="container">                
                <TopEvent />
                </div>
                </section>
                
                {/*Download App*/}
                <DownloadApp />
                {/*end Download App*/}

                {/*<!-- home blog section html start -->*/}
                <section className="blog-section">
                    <div className="container">
                    <div className="section-heading text-center">
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2">
                            <div className="sub-title">Our Mission</div>
                            <h2 className="section-title">Provide the best service for your Event</h2>
                            <p>With ticket and card invitation</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-lg-4">
                            <article className="post">
                            <figure className="feature-image">
                                <a href="#">
                                    <img src="assets/images/img7.png" alt=""/>
                                </a>
                            </figure>
                            <div className="entry-content">
                                <h4>
                                    <a href="#">For your Wedding</a>
                                </h4>
                                <p>Digital card invitation</p>
                            </div>
                            </article>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <article className="post">
                            <figure className="feature-image">
                                <a href="#">
                                    <img src="assets/images/img8.png" alt=""/>
                                </a>
                            </figure>
                            <div className="entry-content">
                                <h4>
                                    <a href="#">For your Event</a>
                                </h4>
                                <p>Digital pass.</p>
                            </div>
                            </article>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <article className="post">
                            <figure className="feature-image">
                                <a href="#">
                                    <img src="assets/images/img9.png" alt=""/>
                                </a>
                            </figure>
                            <div className="entry-content">
                                <h4>
                                    <a href="#">For your formation</a>
                                </h4>
                                <p>Digital ticket.</p>
                            </div>
                            </article>
                        </div>
                    </div>
                    </div>                
                </section>
                {/*<!-- home blog section html end -->*/}
            </main>
        </Fragment>
    );

}

export default MainTicketEvent;