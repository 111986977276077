import { Link } from "react-router-dom";

const MainTopEventTicketItem = ({url,img})=>{

    return (
       <>
         <Link to={url} className="grid-item col-md-4">

         <div >
                            <article className="post">
                            <figure className="feature-image">
                                <a href="#">
                                    <img src={img} alt=""/>
                                </a>
                            </figure>
                            <div className="entry-content">
                                <h4>
                                    <a href="#">For your Event</a>
                                </h4>
                                <p>Digital pass.</p>
                            </div>
                            </article>
                        </div>
            
               
            
        </Link>
       </>
        
    );
}

export default MainTopEventTicketItem;

