import { Link } from "react-router-dom";
import eventStore from "../domain/usecase/event_service";
import { useEffect } from "react";
import CreateEventService from "../domain/usecase/create_event_service";


const TagFilter = ()=>{
    const { fetchFilterEvent } = eventStore();
    const { fetchCategory,category, showLisCat
    } = CreateEventService();

    useEffect(() => {           
        if(!showLisCat)
        fetchCategory();
      }, [fetchCategory]);

    return(
        <>
            <div className="row">                        
                <div className="post-socail-wrap">
                    <div className="social-icon-wrap ">
                    <Link key={1001} to={`/filter_event/${"all event"}`} className="outline-round my-outline-round" onClick={()=>fetchFilterEvent("all_event",1)}>All Event</Link>
                    <Link key={1002} to={`/filter_event/${"food"}`} className="outline-round my-outline-round" onClick={()=>fetchFilterEvent("free",1)}>Free</Link>
                    <Link key={1003} to={`/filter_event/${"get start"}`} className="outline-round my-outline-round" onClick={()=>fetchFilterEvent("get_start",1)}>Get Started</Link>
                    <Link key={1004} to={`/filter_event/${"for you"}`} className="outline-round my-outline-round" onClick={()=>fetchFilterEvent("for_you",1)}>For You</Link>
                    <Link key={1005} to={`/filter_event/${"today"}`} className="outline-round my-outline-round" onClick={()=>fetchFilterEvent("today",1)}>Today</Link>
                    <Link key={1006} to={`/filter_event/${"this weekend"}`} className="outline-round my-outline-round" onClick={()=>fetchFilterEvent("this_week",1)}>This Weekend</Link>
                    <Link key={1007} to={`/filter_event/${"this month"}`} className="outline-round my-outline-round" onClick={()=>fetchFilterEvent("this_month",1)}>This month</Link>
                    {showLisCat&&category!=null&&category.map((item,keyid)=><Link key={keyid} to={`/filter_event/${item.name}`} className="outline-round my-outline-round" onClick={()=>fetchFilterEvent(item.name,1)}>{item.name}</Link>)} 
                    
                    </div>
                </div>
            </div>
        </>
    );
}

export default TagFilter;