import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { saveEvents } from "../../data/api/event_api";
import { creatEventPricing } from "../../data/api/event_pricing_api";
import { signup } from "../../data/api/member";
import { createOrganisation } from "../../data/api/organisation";
import { createCommander, createCommanderPricing } from "../../data/api/commender_api";
import EventPricingModel from "../model/event_pricing_model";



const createMemberOrganisationService = async (eventData) => {

  ///Create organisation with member id
  return await createOrganisation(eventData).then(async (org)=>{
    /// Create member account with admin role
    if(org.sucess && org.data.id!==null){
      const member = await signup(eventData,org.data.id);
      return member;
    }
    return null;
  });
};

const createEventService = async (eventData,key,pricings,navigate)=>{
  
  ///create event
  saveEvents(eventData,key).then(async (event)=>{
    
    ///create pricing
    if(event.sucess){
      for (const element of pricings) {
        toast.success("Event  create with success");
        var pricing = await creatEventPricing(element,event,key);
        if(pricing.success){
          pricing = new EventPricingModel(pricing.data);
          
          toast.success("Pricing  create with success");
        }else{
          toast.error("Pricing  create with success");
        }
        
        // Handle response if needed
      }
     
      navigate(`/event_detail/${event.data.id}`);
      
    }
    
  });
  
}

 const createCommenderTicketService = async (commanderdata,listPrincing)=>{
   return await createCommander(commanderdata).then( async (resp)=>{
    
    if(resp.success){

      listPrincing.forEach(element => {
        createCommanderPricing({
          payMethode: "MTN",
          numberPlace: element.purshareQuantity,
          pricing: element.cost*element.purshareQuantity,
          status: "ACTIVE",
          commanderId: resp.data.id,
          pricingId: element.id
        }).then(async (response)=>{
          if(response.success){
            toast.success("Succes:  ticket "+ element.priceCategorie+" create");  
          }
          
        });
      });
      
    toast.success("Succes:  commender create");  
        
      
      return resp.data
      
      //return new CommanderModel(resp.data);
    }else{
      toast.error("Error:  commender not create");
      return null;
    }
    
  });
 } 

export {createMemberOrganisationService,createEventService,createCommenderTicketService};