import React from "react";

const CinemaTicket = () => {
    return (
        <main id="content" className="site-main">
            <div className="cardWrap">
        <div className="card cardLeft">
          <h1>
            Startup <span>Cinema</span>
          </h1>
          <div className="title">
            <h2>How I Met Your Mother</h2>
            <span>movie</span>
          </div>
          <div className="name">
            <h2>Vladimir Kudinov</h2>
            <span>name</span>
          </div>
          <div className="seat">
            <h2>156</h2>
            <span>seat</span>
          </div>
          <div className="time">
            <h2>12:00</h2>
            <span>time</span>
          </div>
        </div>
        <div className="card cardRight">
          <div className="eye"></div>
          <div className="number">
            <h3>156</h3>
            <span>seat</span>
          </div>
          <div className="barcode"></div>
        </div>
      </div>
        </main>
      
    );
  };
  
  export default CinemaTicket;