import {create} from 'zustand';
import { getCategory } from '../../data/api/category_api';
import { getLocation } from '../../data/api/loacation_api';
import { getTagByCatService } from '../../data/api/tag_api';
import { base64ToFile, convertDate, convertTime } from '../utils';
import { createJSONStorage, persist } from 'zustand/middleware';
import { saveEvents } from '../../data/api/event_api';
import { createEventService } from '../service/eventService';
import { useNavigate } from 'react-router-dom';

const CreateEventService = create(persist((set,get) => ({
  loading: false,
  category:[],
  tags:[],
  selectTags:[],
  places:[],
  page:0,
  currentTag:[],
  pricings: [],
  eventData: {
    id: null,
    title: '',//ok
    organizer: '',
    latitude: '',//ok
    longitude: '',//ok
    area: '',//ok
    tag: '', //ok
    summary: '',//ok
    description: '',//ok
    offline: false, //ok
    startHour: '',//ok
    endHour: '',//ok
    country: '',//ok
    autherLocation: '',
    quantity: 0,//ok
    periodique: 0,//ok
    singleEvent: false,
    displayStartTime: true,
    displayEndTime: true,
    startDate: '',//ok
    dateEnd: '',//ok
    createdDate: convertDate(""),//ok
    mainImage: '',//ok
    eventCategoryid: 1,
    typeid: null,//ok category id
    memberMail: "",
    descriptionImage1: '',//ok
    recurcingEvent: false,//ok
    descriptionImage2: '',//ok
    firstTime: true,
    activate: true
  },
  currentMainImage: "",
  currentDescriptionImage1: "",
  currentDescriptionImage2: "",
  storeMainImage: "",
  storeDescriptionImage1: "",
  storeDescriptionImage2: "",
  error: "",
  pageSize:100,
  showStep1:true,
  showStep2: false,
  showStep3: false,
  showStep4: false,
  showStep5: false,
  locatedChoice: 1,
  showListTags: false,
  showLisCat: false,
  loadingTags: false,
  loadingCat:false,
  loadingPlace:false,
  isOnlineActivate: true,
  isOffLineActivate: false,
  step1Error:["","",""],
  resetStore:()=>{
   
    set({eventData:{
      id: null,
      title: '',//ok
      organizer: '',
      latitude: '',//ok
      longitude: '',//ok
      area: '',//ok
      tag: '', //ok
      summary: '',//ok
      description: '',//ok
      offline: false, //ok
      startHour: '',//ok
      endHour: '',//ok
      country: '',//ok
      autherLocation: '',
      quantity: 0,//ok
      periodique: 0,//ok
      singleEvent: false,
      displayStartTime: true,
      displayEndTime: true,
      startDate: '',//ok
      dateEnd: '',//ok
      createdDate: convertDate(""),//ok
      mainImage: '',//ok
      eventCategoryid: 1,
      typeid: null,//ok category id
      memberid: 4,
      descriptionImage1: '',//ok
      recurcingEvent: false,//ok
      descriptionImage2: '',//ok
      firstTime: true,
      activate: true,
      locationUrl: "",
    },
    step1Error:["","",""],
    step2Error:["","",""],
    step3Error:[false,false,false,false,""],
    step4Error:['',''],
    step5Error:[],
    category:[],
    tags:[],
    selectTags:[],
    places:[],
    page:0,
    currentTag:[],
    pricings: [],});
    get().fetchCategory();
  },
  storeImage:(key,file)=>{
    switch(key){
      case "mainImage": set({storeMainImage:file}); localStorage.setItem("currentMainImage",file); break;
      case "descriptionImage1": set({storeDescriptionImage1:file}); localStorage.setItem("currentDescriptionImage1",file); break;
      case "descriptionImage2": set({storeDescriptionImage2:file}); localStorage.setItem("currentDescriptionImage2",file); break;
      default: break;
    }
  },
  setstep1Error:(key,message)=>{
    switch(key){
      case 1: set({step1Error:[message,get().step1Error[1],get().step1Error[2]]}); break;
      case 2: set({step1Error:[get().step1Error[0],message,get().step1Error[2]]}); break;
      case 3: set({step1Error:[get().step1Error[0],get().step1Error[1],message]}); break; 
      default: break;
    }
  },
  step2Error:["","",""],
  setstep2Error:(key,message)=>{
    switch(key){
      case 1: set({step2Error:[message,get().step2Error[1],get().step2Error[2]]}); break;
      case 2: set({step2Error:[get().step2Error[0],message,get().step2Error[2]]}); break;
      case 3: set({step2Error:[get().step2Error[0],get().step2Error[1],message]}); break;
      default: break;
    }
  }, 
  step3Error:[false,false,false,false,""],
  setstep3Error:(key,message)=>{
    switch(key){
      case 1: set({step3Error:[message,get().step3Error[1],get().step3Error[2],get().step3Error[3],get().step3Error[4]]}); break;
      case 2: set({step3Error:[get().step3Error[0],message,get().step3Error[2],get().step3Error[3],get().step3Error[4]]}); break;
      case 3: set({step3Error:[get().step3Error[0],get().step3Error[1],message,get().step3Error[3],get().step3Error[4]]}); break;
      case 4: set({step3Error:[get().step3Error[0],get().step3Error[1],get().step3Error[2],message,get().step3Error[4]]}); break;
      case 5: set({step3Error:[get().step3Error[0],get().step3Error[1],get().step3Error[2],get().step3Error[3],message]}); break;
      default: break;
    }
  },
  step4Error:['',''],
  setstep4Error:(key,message)=>{
    switch(key){
      case 1: set({step4Error:[message,get().step4Error[1]]}); break;
      case 2: set({step4Error:[get().step4Error[0],message]}); break;
      default: break;
    }
  },
  step5Error:[],
  addPricing: (p)=>{
    set({pricings:get().pricings.add(p)});
  },
  setCurrentTag:(e)=>{
    set({currentTag:e});
  },
  setEventData:(key,value)=>{ 
    const updateEventData = get().eventData;

    switch(key){
      case "title": updateEventData.title = value; break;
      case "summary": updateEventData.summary = value;  break;
      case "description": updateEventData.description = value; break;
      case "latitude": updateEventData.latitude = value;  break;
      case "longitude": updateEventData.longitude = value;  break;
      case "country": updateEventData.country = value; break;
      case "autherLocation": updateEventData.autherLocation = value; break;
      case "periodique": updateEventData.periodique = value;  break;
      case "area": updateEventData.area = value; break;
      case "tag": updateEventData.tag = value;break;
      case "quantity": updateEventData.quantity = value; break;
      case "typeid" : updateEventData.typeid = value;updateEventData.eventCategoryid = value; break;
      case "startDate" :updateEventData.startDate = convertDate(value) ; break;
      case "dateEnd" :  updateEventData.dateEnd = convertDate(value);  break;
      case "startHour" : updateEventData.startHour = convertTime(value); break;
      case "endHour" :  updateEventData.endHour = convertTime(value);break;
      case "recurcingEvent" :  updateEventData.recurcingEvent = value;  break;
      case "mainImage" :  updateEventData.mainImage = value;break;
      case "descriptionImage1" :  updateEventData.descriptionImage1 = value;  break;
      case "descriptionImage2" :  updateEventData.descriptionImage2 = value; break;
      case "currentMainImage" : set({currentMainImage:value});break;
      case "currentDescriptionImage1" :  set({currentDescriptionImage1:value});break;
      case "currentDescriptionImage2" : set({currentDescriptionImage2:value});break;
      case "locationUrl" : updateEventData.locationUrl = value; break;
      
      default: console.log("************** unkonw key *****************");
    }

    set({eventData:updateEventData})
  },
  onlineActivate:()=>{
    set({isOnlineActivate:true,isOffLineActivate:false});
  },
  offLineActivate:()=>{
    set({isOnlineActivate:false,isOffLineActivate:true});
  },
  handleDeleteTag:(tagToDelete)=>{
    set({tags: get().tags.filter((tag) => tag !== tagToDelete)});
  },
  handleAddTag:(event, newTags)=>{
    set({tags:newTags});
  },
  getAllTags:async (id)=>{
    set({ loadingTags: true,error:""});
    try{
      const resp = await getTagByCatService(id,0,100);
      set({showListTags: resp.sucess});
      set({tags: resp.sucess?resp.data:[],error:"No data found",loadingTags: false });
    }catch(e){
      set({ error: e.message, loadingTags: false });
    }
    
  },
  switchStep: (step)=>{   
    switch(step){
      case 1: set({ showStep1: true, showStep2:false, showStep3:false, showStep4:false, showStep5:false  });   break;
      case 2: set({ showStep1: false, showStep2:true, showStep3:false, showStep4:false,showStep5:false  });   break;
      case 3: set({ showStep1: false, showStep2:false, showStep3:true, showStep4:false,showStep5:false });   break;
      case 4: set({ showStep1: false, showStep2:false, showStep3:false, showStep4:true,showStep5:false });   break;
      case 5: set({ showStep1: false, showStep2:false, showStep3:false, showStep4:false,showStep5:true });   break;
      default: set({ showStep1: true, showStep2:false, showStep3:false, showStep4:false,showStep5:false});    
    }
  },
  toogleLocatedChoice:(choice)=>{
    set({locatedChoice: choice });    
  },
  refreshPlace: async (location)=>{
    set({ loadingPlace: true,error:""});
    try{
      const resp = await getLocation(location);
      set({ loadingPlace: false, places:resp});
      
    }catch(e){
      console.log("++++++++++++++ error +++++++++++++");
      console.log(e.message);
      set({ error: e.message, loadingPlace: false });
    }
    
  },
  fetchCategory: async  ()=>{
    set({ loadingCat: true,error:""});
    try {
        const categories = await getCategory(get().page,100);
        
        if(categories.data !==null){
            
          set({ category:categories.data, loadingCat: false,showLisCat:true });    
        }else{
          set({loadingCat: false });
          console.log("************************* error ****************************");
        }
        
      } catch (error) {
        set({ error: error.message, loadingCat: false });
      }
  },
  saveEvent: async (pricings,navigate)=>{
    set({loading:true});
    const key = localStorage.getItem('key');
    try{    
      if(get().eventData.memberMail!=null){
        console.log("get().eventData ------- ",get().eventData);
        await createEventService(get().eventData,key,pricings,navigate);
      }
       
    }catch(e){
      console.log(e);
    }
    
    set({loading:false})
  },
  initData:()=>{
    set({loading:false})
    const updateEventData = get().eventData;
    const memberMail = localStorage.getItem('username');
    updateEventData.memberMail = memberMail;
    updateEventData.currentMainImage = base64ToFile(localStorage.getItem("currentMainImage"),"currentMainImage.jpg") ;
    updateEventData.currentDescriptionImage1 = base64ToFile(localStorage.getItem("currentDescriptionImage1"),"currentDescriptionImage1.jpg");
    updateEventData.currentDescriptionImage2 = base64ToFile(localStorage.getItem("currentDescriptionImage2"),"currentDescriptionImage2.jpg");

    updateEventData.mainImage = base64ToFile(localStorage.getItem("currentMainImage"),"currentMainImage.jpg") ;
    updateEventData.descriptionImage1 = base64ToFile(localStorage.getItem("currentDescriptionImage1"),"currentDescriptionImage1.jpg");
    updateEventData.descriptionImage2 = base64ToFile(localStorage.getItem("currentDescriptionImage2"),"currentDescriptionImage2.jpg");
    set({eventData:updateEventData});
   
  }
}),{
  name: 'create-event-storage', // unique name
  storage: createJSONStorage(() => sessionStorage),
}));

export default CreateEventService;