import MainEventTicketItem from "./main_event_ticket_item";
import MainTopEventTicketItem from "./main_top_event_ticket_item";

const TopEvent = ()=>{
    return(
        <>
        <div className="container">
            <div className="sub-title">FIND SOME ORGANISATION</div>
               <div className="about-page-section">
                  <div className="section-client section-client-slider">
                     <div className="container">
                        <div className="client-wrap client-slider text-center">
                           <MainTopEventTicketItem url={""} img={"assets/images/product-img1.jpg"}/>
                           <MainTopEventTicketItem url={""} img={"assets/images/product-img1.jpg"}/>
                           <MainTopEventTicketItem url={""} img={"assets/images/product-img1.jpg"}/> 
                           <MainTopEventTicketItem url={""} img={"assets/images/product-img1.jpg"}/>
                           <MainTopEventTicketItem url={""} img={"assets/images/product-img1.jpg"}/> 
                           <MainTopEventTicketItem url={""} img={"assets/images/product-img1.jpg"}/>
                           <MainTopEventTicketItem url={""} img={"assets/images/product-img1.jpg"}/>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
        </>
    );
}

export default TopEvent;